<template>
  <div class="bg-info">
    <!-- Header -->

    <div class="header bg-primary py-6 py-lg-5">
      <div class="container header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class="text-white">Not Found !</h1>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-info" points="3560 20 1560 20 0 800"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <img src="/img/icons/cards/sraa-notfound.gif" />
              </div>
              <div class="text-center">
                <router-link to="/dashboard" class="btn btn-primary my-4"
                  >Go to Dashboard</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header py-6 py-lg-5">
      <div class="container header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <p class="text-center text-white mb-8 font-weight-bold">
              Made with 💚 in <img src="img/icons/flags/SA.png" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
