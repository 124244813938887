/*!

=========================================================
* Sraa App - v2.0.2
=========================================================

* Product Page: https://wesla.saproduct/argon-dashboard
* Copyright 2022 Wesla (https://wesla.sa)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import axios from "axios";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/dist/index.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import user from "@/store/user";

const axiosInstance = axios.create({
  withCredentials: false,
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "MDc4MzM4NH0xEj85I1aYiwq",
  },
});
axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (err) => {
    console.log(err);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const status = err.response?.status || 500;
    // we can handle global errors here
    switch (status) {
      // authentication (token related issues)
      case 401: {
        // logout the user
        localStorage.removeItem("token");
        // redirect to log in
        window.location.href = "/login";
      }
      // generic api error (server related) unexpected
      // eslint-disable-next-line no-fallthrough
      default: {
        return Promise.reject(err);
      }
    }
  }
);

function generateAvatar(
  text,
  foregroundColor = "white",
  backgroundColor = "black"
) {
  // Get First Character each character from text
  text = text
    .split(" ")
    .map((word) => word[0])
    .join("");
  // Random Color
  // foregroundColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  backgroundColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = 200;
  canvas.height = 200;

  // Draw background
  context.fillStyle = backgroundColor;
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  context.font = "bold 100px Assistant";
  context.fillStyle = foregroundColor;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL("image/png");
}

const options = { containerClassName: "ct-notification" };
const appInstance = createApp(App);
const mount = async () => {
  // Get User's token

  const token = localStorage.getItem("token");
  if (token) {
    appInstance.config.globalProperties.$token = "bearer " + token;
  }
  appInstance.config.globalProperties.$api = { ...axiosInstance };
  appInstance.config.globalProperties.$generateAvatar = generateAvatar;
  appInstance.config.globalProperties.$filters = {
    FormatDate(date) {
      const dateObj = new Date(date);
      const month = dateObj.toLocaleString("default", { month: "short" });
      const day = dateObj.getDate();
      const year = dateObj.getFullYear();
      return `${year} ${month} ${day}`;
    },
    MinutesAgo(date) {
      const dateObj = new Date(date);
      const now = new Date();
      const diff = now - dateObj;
      return Math.floor(diff / 60000);
    },
    PhoneNumber(phone) {
      // Remove key
      phone = phone.replace("+", "");
      phone = phone.replace("00966", "");
      phone = "0" + phone;
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    },
  };
  appInstance.use(router);
  appInstance.use(Toast, options);

  appInstance.use(ArgonDashboard);
  appInstance.use(user);
  appInstance.mount("#app");
};
mount().then();
export default {
  appInstance,
  axiosInstance,
};
