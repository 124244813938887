<script>
import BaseButton from "@/components/BaseButton.vue";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload.vue";
import TagsInput from "@/components/Inputs/TagsInput.vue";
import Modal from "@/components/Modal.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "PaymentModal",
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    const iframe = document.getElementById("3d-secure-iframe");
    const interval = setInterval(() => {
      if (iframe.contentWindow?.location) {
        if (iframe.contentWindow?.location?.href.includes("subscription")) {
          console.log("success");
          clearInterval(interval);
          this.closeQueueModal();
        }
      }
    }, 100);
  },
  setup() {},
  methods: {
    closeQueueModal() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <modal v-model:show="show" :size="'lg'" @close="closeQueueModal">
    <template v-slot:header>
      <iframe
        id="3d-secure-iframe"
        ref="paymentframe"
        :src="url"
        frameborder="0"
        class="moyasar-popup"
        height="100%"
        width="100%"
      ></iframe>
    </template>
  </modal>
</template>

<style scoped></style>
