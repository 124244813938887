<template>
  <div class="bg-info">
    <!-- Header -->

    <div class="header bg-primary py-6 py-lg-5">
      <div class="container header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class="text-white">Terms & Conditions</h1>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-info" points="3560 20 1560 20 0 800"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container-fluid mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <h2>Introduction</h2>
              <p>
                SRAA App respects your privacy and appreciates your concern
                about how to handle and share your personal information. We
                value your trust in us and always work to protect the privacy of
                your data (individuals – companies) with the use of complete
                encryption to protect sent data. In this Privacy Policy, you
                agree that we will retain your registration information and do
                not mind using this data to improve the service and conduct
                transactions that you The disclosure of your information to
                third parties may require the processing of this information on
                our behalf, as well as other cases.
              </p>

              <h2>Information about individuals:</h2>
              <p>
                The available means of communication that facilitate
                communication, establishment, and confirmation of subscription
                membership, including but not limited to, phone numbers and
                Usernames are collected. We collect the number of visits and
                demographic information such as gender, age, and state to find
                accurate statistics for our users, to customize appropriate
                offers, and to provide services based on their interests and
                preferences. The number of visits you have made, the number of
                reviews for restaurants, admired or evaluated in order to
                improve the user experience so that we can tailor the user
                experience more and more to the application.
              </p>

              <h2>How to use the data:</h2>
              <ul>
                <li>
                  Conduct market research, including conducting statistical
                  analysis to understand user behaviour, including offering it
                  to third parties to perform certain services on our behalf.
                </li>
                <li>Enable us to meet commitments and commitments.</li>
                <li>
                  Meet your needs according to your interests and inform you of
                  all your products, services, events, and special offers. And
                  reach the best communication strategy for you, including
                  (e-mail messages).
                </li>
              </ul>

              <h2>What do we collect about you and why?</h2>
              <ul>
                <li>
                  <span class="font-weight-bold">location</span>: Using location
                  is important for searching for the nearest store by location.
                  Also, there is a possibility that the location will help us to
                  make check-in upon arrival to the store, the check-in will be
                  automatically and the host knows that the guest is near to
                  restaurant, the host can show how far the guest and the
                  estimated time to arrive to the store.
                </li>
                <li>
                  <span class="font-weight-bold">Phone</span>: To confirm
                  membership, and to communicate between the store, customer,
                  and SRAA team.
                </li>
                <li>
                  <span class="font-weight-bold"
                    >Gender, Age, and number of visits</span
                  >: So, we rely on accurate user statistics, and for
                  customization offers.
                </li>
              </ul>

              <h2>Our responsibility</h2>
              <p>
                In the limit allowed by law, SRAA provides the service and
                content on an "as is" and "as available" basis and we do not
                have any representation or warranty as to the content or
                availability of the service or it will be error-free or will be
                corrected Disadvantages. Q & A and our partner restaurants will
                not be liable for any direct, indirect, special, or
                consequential loss of any consequences found in the contract or
                outside the contract or caused by your use or inability to use
                our service. In the event that Q & A or the partner restaurant
                has a responsibility to you, our overall liability will be
                limited to the waiting list. This does not include or limit the
                liability of SRAA or the partner restaurant for any subject that
                would be illegal in not taking responsibility such as in the
                event of death or personal injury caused by negligence, fraud,
                or false statements.
              </p>

              <h2>Events beyond our control</h2>
              <p>
                Neither party shall be responsible for the other because of the
                delay or non-performance of its obligations under this Agreement
                for any reason beyond our control. This includes, without
                limitation, any of the following: Judgment, fate, government
                action, war, fire, flood, explosion, or civil unrest. Or failure
                in servers and the Internet.
              </p>

              <h2>The independence of the items</h2>
              <p>
                If any item is judged to be illegal or unenforceable, the
                continuity of the remaining items with all effectiveness shall
                not be prejudiced.
              </p>

              <h2>Our right to change these terms and conditions</h2>
              <p>
                SRAA may review these Terms of Use at any time and modify this
                page. You are bound by any change to these terms and so you are
                expected to visit this page from time to time to be aware of any
                changes.
              </p>

              <h2>Law and the judiciary</h2>
              <p>
                The Saudi courts have jurisdiction over any allegation arising
                out of or in connection with any use of our services. Any
                dispute or claim arising out of or relating to these terms or
                subject matter (including disputes or non-contractual claims)
                will be governed by law in Saudi Arabia
              </p>

              <h1>Terms of use of SRAA for Application and applications</h1>
              <p>
                This page (together with the documents referred to) will inform
                you of the terms of use that you will apply when accessing our
                Application (referred to as "the Application") or any
                application available through an App Store or otherwise ("our
                Services") as a visitor or registered user. PLEASE READ THESE
                TERMS CAREFULLY BEFORE USING OUR Application OR SERVICES. When
                you access our Application or services, you acknowledge your
                acceptance of these terms and agree to be bound by them. If you
                disagree with these terms, please do not use our Application or
                our services.
              </p>

              <ul>
                <li>
                  <h4>Use of our service or services</h4>
                  <p>
                    Your access to our Application or service is temporarily
                    allowed, and we have the right to withdraw or modify your
                    access to our Application or service without notice (details
                    below). We are not responsible for any reason why our
                    Application or service is unavailable at any time or for any
                    length of time. From time to time, we may limit the extent
                    of registered users' access to some parts of our Application
                    or service. You are responsible for keeping your login
                    details confidential and for any activities you receive in
                    your account. If you have any questions about your login
                    details or if you feel that they have been misused, contact
                    us immediately to let us know. We have the ability to
                    disable your account at any time.
                  </p>
                </li>
                <li>
                  <h4>Acceptable Use</h4>
                  <p>
                    You can use our service for legal reasons only. You may not
                    use our Application or Service in any way that may infringe
                    any local or international law, send, receive, tolerate,
                    use, or reuse any material that does not comply with the
                    content standards of our services set forth in Section 5
                    below. You also agree that no portion of our Application or
                    Service shall be accessed without permission, interference,
                    damage, or disruptions, or any network or equipment used in
                    our service.
                  </p>
                </li>
                <li>
                  <h4>Interactive features in our Application</h4>
                  <p>
                    We can from time to time provide certain interactive
                    features that will allow you to interact through our
                    Application or our service such as chat rooms. We do not
                    disclose any interactive service we provide, but we can
                    remove content that violates these terms and conditions. If
                    we decide to supervise an interactive service, we will
                    inform you before using the service and we will provide you
                    with the means of communication with the supervisor in the
                    event of any problem or difficulty.
                  </p>
                </li>
                <li>
                  <h4>Content Standards</h4>
                  <p>
                    These standards apply to all contributions you make to our
                    service ("Contributions"), and any related interactive
                    services. You must adhere to these standards and the message
                    as well. These standards apply to the full contribution and
                    to each part of them as well. Contributions must be accurate
                    (if they are facts), be truthful (if they are opinions), and
                    be constrained by the law applicable in Saudi Arabia and in
                    any country that comes from it. Contributions should not:
                  </p>
                  <ul>
                    <li>
                      contain any defamatory material against any person, be
                      obscene, offensive, hateful, inflammatory, defamatory,
                      promote violence or differentiate race, sex, religion,
                      nationality, disability, or age
                    </li>
                    <li>
                      Copyright, copyright, or trademark infringement of any
                      person
                    </li>
                    <li>
                      Be deceptive to any person or infringe upon any third
                      party's legal duty, such as contractual duty or
                      confidential duty, or promoting illegal acts
                    </li>
                    <li>
                      be threatened, offensive or transgressive of anyone else's
                      privacy, harass or harass, harass, embarrass, intimidate
                      anyone else
                    </li>
                    <li>
                      be used to impersonate any person or false claim of your
                      identity or cooperation with any person or give the
                      impression that it stems from us if this is not the truth
                    </li>
                    <li>
                      Promote, promote, or assist in any illegal act such as
                      copyright infringement or misuse of the computer
                    </li>
                  </ul>
                </li>

                <li>
                  <h4>Comment or Termination</h4>
                  <p>
                    Failure to comply with Section 3 (Acceptable Use) or/or 4
                    (Content Standards) in these Terms constitutes a fundamental
                    breach of the Terms of Use and may result from any of the
                    following actions:
                  </p>
                  <ul>
                    <li>
                      Immediate, temporary, or permanent withdrawal of your
                      right to use our service
                    </li>
                    <li>
                      Immediate, temporary, or permanent withdrawal of your
                      right to contribute to our service
                    </li>
                    <li>Give you a warning</li>
                    <li>
                      Take legal action against you and include compensation for
                      all costs (including but not limited to administrative and
                      legal costs) resulting from the violation
                    </li>
                  </ul>
                </li>

                <li>
                  <h4>Intellectual property rights</h4>
                  <p>
                    We are the owners of all intellectual property rights in our
                    Application, our service, and the material published on it
                    (except for your contributions). These works are protected
                    by copyright and copyright treaties around the world. All
                    rights reserved. You may not copy, reproduce, republish,
                    upload, publish, broadcast, transmit, make available to
                    anyone, or use any content on our Application in any way
                    other than your own non-commercial use.
                  </p>
                </li>

                <li>
                  <h4>Reliance on published information</h4>
                  <p>
                    Any comments posted on our service should not be considered
                    reliable advice. Therefore, we disclaim any liability for
                    reliance on these comments posted by any visitor on our
                    service, or by anyone who knows its content.
                  </p>
                </li>

                <li>
                  <h4>Our Application and service are constantly changing</h4>
                  <p>
                    We aim to constantly update our Application and service and
                    can change its content at any time. If necessary, we can
                    stop access to our Application and service or shut down for
                    an indefinite period. Any content on our Application or
                    service may at any time be outdated, and we have no
                    obligation to update these areas.
                  </p>
                </li>

                <li>
                  <h4>Our responsibility</h4>
                  <p>
                    We took all precautions when processing our Application and
                    our service. However, we will not be responsible for any
                    errors or omissions related to the content or any technical
                    problems you may encounter when using our service or our
                    Application. If we are informed of any errors on our
                    Application or our service, we will try to correct them as
                    soon as possible. To the extent permitted by law, we will
                    completely exclude liability (whether arising out of
                    contract, negligence, or otherwise) from any loss or damage
                    that may be incurred by you or any third party related to
                    our Application, service, or any Application linked to our
                    Application and any content posted on it. This does not
                    affect our liability in the event of personal death or
                    injury arising out of our negligence, our responsibility in
                    the event of fraud, making false statements or providing
                    false statements on a fundamental matter, or any other
                    liability that cannot be excluded or restricted under
                    applicable law.
                  </p>
                </li>

                <li>
                  <h4>Collect Data about you</h4>
                  <p>
                    Data about you your visits to our Application, and the use
                    of our service You agree to collect certain data about you
                    such as your phone number, name, and current location as a
                    result of your use of our service.
                  </p>
                </li>

                <li>
                  <h4>Upload content to our Application and service</h4>
                  <p>
                    Any Content you upload to our Services or the information we
                    collect in Section 10 shall be deemed non-confidential and
                    non-proprietary. You understand and agree that we have the
                    right to use, copy, distribute, sell, and disclose such
                    content or information to third parties for any purpose
                    related to our work. If such content is protected by
                    intellectual property rights, you grant us a permanent,
                    international, royalty-free license to use, modify,
                    distribute, sell and disclose such content to third parties
                    for any purpose related to our work.
                  </p>
                </li>

                <li>
                  <h4>Links from our Application</h4>
                  <p>
                    When our Application contains links to other Applications
                    and sources provided by third parties, these links provide
                    only your information. We have no control over the content
                    of these Applications or sources and do not accept any
                    responsibility for them or for any loss or damage that may
                    arise from your use of them.
                  </p>
                </li>

                <li>
                  <h4>Judiciary and applicable law</h4>
                  <p>
                    The Saudi courts have jurisdiction over any allegation
                    arising out of, or relating to, visiting our Application or
                    using our services. Any dispute or claim arising out of or
                    relating to these terms or subject matter (including
                    disputes or non-contractual claims) will be governed by law
                    in Saudi Arabia.
                  </p>
                </li>

                <li>Changes</li>
                <p>
                  We can review these terms at any time by changing this page.
                  You are expected to check this page from time to time to be
                  aware of any changes we are making because you are bound by
                  it.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header py-6 py-lg-5">
      <div class="container header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <p class="text-center text-white mb-8 font-weight-bold">
              Made with 💚 in <img src="img/icons/flags/SA.png" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import BaseOTP from "@/components/Inputs/BaseOTP.vue";
import vueRecaptcha from "vue3-recaptcha2";
export default {
  components: {
    vueRecaptcha,
    BaseOTP,
    Form,
  },
  data() {
    return {
      timer: 2,
      loading: false,
      otp: false,
      error_message: "",
      modelOtp: {
        otp: "",
        temporary_token: "",
      },
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    recaptchaVerified(response) {
      this.model.t = response;
      console.log(response);
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},
    recaptchaError(reason) {},
    setTimer() {
      this.interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        }
      }, 1000);
    },
    onSubmitOtp() {
      this.loading = true;
      this.error_message = "";
      this.$api
        .post("/merchant/auth", this.modelOtp)
        .then(async (res) => {
          const data = res.data;
          this.loading = false;
          const token = data.data.token;
          await localStorage.setItem("token", token);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.loading = false;
          this.error_message = error.response.data.message;
        });
    },
    onSubmit() {
      this.timer = 60;
      this.interval = null;
      this.loading = true;
      this.error_message = "";
      this.$api
        .post("/merchant/login", this.model)
        .then(async (res) => {
          const data = res.data;
          if (data.data.require_otp) {
            this.loading = false;
            this.otp = true;
            this.modelOtp.temporary_token = data.data.temporary_token;
            this.setTimer();
          } else {
            this.loading = false;
            const token = data.data.token;
            await localStorage.setItem("token", token);
            this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error_message = error.response.data.message;
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      // Start With 05
      email: Yup.string().email("Email is not valid").required().label("Email"),
      password: Yup.string().required().label("Password"),
      capcha: Yup.string().required().label("Capcha"),
    });
    const schemaOtp = Yup.object().shape({
      // Start With 05
      OTP: Yup.string()
        .min(6, "OTP should be 6 Digits")
        .max(6, "OTP should be 6 Digits")
        .required()
        .label("OTP"),
    });

    return {
      schema,
      schemaOtp,
    };
  },
};
</script>
