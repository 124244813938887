<template>
  <div class="card">
    <div class="border-0 card-header">
      <h3 class="mb-0">Queue table</h3>
    </div>

    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      :data="tableData"
      v-if="tableData.length > 0"
    >
      <el-table-column label="Queue" min-width="250px" prop="name" sortable>
        <template v-slot="{ row }">
          <div class="media align-items-center" v-if="row.queue">
            <a href="#" class="avatar rounded-circle mr-3">
              <img alt="Image placeholder" :src="row.queue.image" />
            </a>
            <div class="media-body">
              <span class="font-weight-600 name mb-0 text-sm"
                >[{{ row.queue.id }}] - {{ row.queue.name }}</span
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Max People Per Order"
        prop="Max People Per Order"
        min-width="150px"
        sortable
      >
        <template v-slot="{ row }">
          <div v-if="row.queue">
            <span class="text-lg ni-bold">{{ row.queue.max_people }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Status" min-width="170px" prop="status" sortable>
        <template v-slot="{ row }">
          <div v-if="row.queue">
            <badge class="badge-dot mr-4" type="">
              <i
                :class="`bg-${row.queue.is_closed ? 'danger' : 'success'}`"
              ></i>
              <span class="status">Open</span>
            </badge>
            <badge class="badge-dot mr-4" type="">
              <i :class="`bg-${row.queue.is_full ? 'success' : 'danger'}`"></i>
              <span class="status">Is Full</span>
            </badge>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="People In queue" min-width="190px">
        <template v-slot="{ row }">
          <div class="avatar-group" v-if="row.people">
            <a
              v-for="person in row.people"
              href="#"
              class="avatar avatar-sm rounded-circle"
              data-toggle="tooltip"
              data-original-title="Ryan Tompson"
            >
              <img
                :alt="person.name"
                :src="$generateAvatar(person.name ? person.name : 'AA')"
                :title="person.name || ''"
              />
            </a>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Tags" min-width="190px">
        <template v-slot="{ row }">
          <div class="avatar-group" v-if="row.queue">
            <a
              v-for="tag in row.queue.tags"
              href="#"
              class="avatar avatar-sm rounded-circle"
              data-toggle="tooltip"
              data-original-title="Ryan Tompson"
            >
              <img :alt="tag.name" :src="tag.icon" :title="tag.name" />
            </a>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="Capacity"
        prop="Capacity"
        min-width="240px"
        sortable
      >
        <template v-slot="{ row }">
          <div class="d-flex align-items-center" v-if="row.queue">
            <span class="completion mr-2"
              >{{ row.queue.people_enqueue * 10 }}%</span
            >
            <div>
              <base-progress
                :value="row.queue.people_enqueue * 10"
                :size="'10'"
                :animated="true"
              />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="180px">
        <template v-slot="{ row }">
          <el-dropdown trigger="click" class="dropdown">
            <span class="btn btn-sm btn-icon-only text-light">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show">
                <a
                  class="dropdown-item"
                  @click.prevent="addRandomUser(row.queue.id)"
                  href="#"
                  >Add Random User</a
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination
        v-model="currentPage"
        :total="tableData.length"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";

export default {
  name: "queue-list-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      tableData: [],
      projects: [
        {
          img: "img/theme/bootstrap.jpg",
          title: "Argon Design System",
          budget: "$2500 USD",
          status: "pending",
          statusType: "warning",
          completion: 60,
        },
      ],
      currentPage: 1,
    };
  },
  mounted() {
    this.getQueues();
  },
  methods: {
    getQueues() {
      this.$api.get("admin_p/queues").then((res) => {
        this.tableData = res.data.data;
      });
    },
    addRandomUser(id) {
      this.$api
        .post("admin_p/socket/add-user", {
          queue_id: id,
        })
        .then(() => {
          this.getQueues();
        });
    },
  },
};
</script>
