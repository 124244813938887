<template>
  <div class="container-fluid">
    <div class="pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="pricing card-group flex-column flex-md-row mb-3">
            <card
              :gradient="plan.main ? 'info' : ''"
              v-for="(plan, index) in plans"
              :key="index"
              header-classes="bg-transparent"
              footer-classes="bg-transparent"
              body-classes="px-lg-5"
              :class="{
                'card-pricing border-0 text-center mb-4': true,
                'zoom-in shadow-lg rounded': plan.main,
              }"
            >
              <template v-slot:header>
                <h4
                  class="text-uppercase ls-1 text-primary py-3 mb-0"
                  :class="{ 'text-white': plan.main }"
                >
                  {{ plan.name }}
                </h4>
              </template>
              <div
                class="display-1 text-primary"
                :class="{ 'text-white': plan.main }"
              >
                {{ plan.price }}
                <a
                  class="text-primary text-sm"
                  :class="{ 'text-white': plan.main }"
                  >SAR</a
                >
              </div>
              <span :class="{ 'text-white': plan.main }">Monthly</span>

              <ul class="list-unstyled my-4">
                <li
                  v-for="(feature, featureIndex) in plan.features"
                  :key="index + '-' + featureIndex"
                >
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                      >
                        <i :class="feature.icon"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2" :class="{ 'text-white': plan.main }">{{
                        feature.title
                      }}</span>
                    </div>
                  </div>
                </li>
              </ul>

              <base-button
                v-if="plan.name !== 'Free'"
                :type="plan.main ? 'secondary' : 'primary'"
                class="mb-3"
                @click="$emit('selectPlan', plan)"
                >Subscribe</base-button
              >

              <template v-slot:footer v-if="plan.name !== 'Free'">
                <a
                  href="#!"
                  :class="{ 'text-white': plan.main, 'text-light': !plan.main }"
                  >Contact sales</a
                >
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "Plans",
  data() {
    return {
      plans: [
        {
          id: 2,
          name: "Free",
          main: false,
          price: 0,
          description: "Free plan",
          features: [
            {
              title: "One Store",
              icon: "fas fa-store",
            },
            {
              title: "Two queues per store",
              icon: "fas fa-ellipsis-h",
            },
            {
              title: "Up to 8 waiting persons per queue",
              icon: "fas fa-users",
            },
          ],
        },
        {
          id: 1,
          name: "Growth",
          main: true,
          price: 99,
          description: "Growth plan",
          features: [
            {
              title: "Two stores",
              icon: "fas fa-store",
            },
            {
              title: "8 queues per store",
              icon: "fas fa-ellipsis-h",
            },
            {
              title: "Up to 16 waiting persons per queue",
              icon: "fas fa-users",
            },
          ],
        },
        {
          id: 3,
          name: "Professional",
          main: false,
          price: 249,
          description: "Professional plan",
          features: [
            {
              title: "Four stores",
              icon: "fas fa-store",
            },
            {
              title: "12 queues per store",
              icon: "fas fa-ellipsis-h",
            },
            {
              title: "Up to 64 waiting persons per queue",
              icon: "fas fa-users",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  components: { BaseButton },
};
</script>
