<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>

    <!-- Contact card -->
    <div class="container-fluid mt--6">
      <card v-if="userPlan" v-loading="!userPlan">
        <!-- Card body -->
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- Avatar -->
            <i
              size="xl"
              class="avatar avatar-xl rounded-circle fa fa-store bg-primary"
            />
          </div>
          <div class="col ml--2">
            <h4 class="mb-0">
              <a href="#!">Create Store</a>
            </h4>
            <p class="text-sm text-muted mb-0">Start</p>
            <span
              :class="{
                'text-danger': userPlan.number_of_stores - stores.length <= 0,
                'text-success': userPlan.number_of_stores - stores.length > 0,
              }"
              >●
            </span>
            <small
              >{{ userPlan.number_of_stores - stores.length }} Stores
              remaining</small
            >
          </div>
          <div class="col-auto">
            <base-button
              :disabled="userPlan.number_of_stores - stores.length <= 0"
              type="primary"
              size="lg"
              :icon="true"
              @click="
                () => {
                  $router.push('/stores/create');
                }
              "
            >
              <span>Create</span>
              <i class="fa fa-arrow-right"></i>
            </base-button>
          </div>
        </div>
      </card>

      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Stores</h3>
            </div>
          </div>
        </div>

        <el-table
          v-loading="loading"
          class="table-responsive align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="stores"
        >
          <el-table-column label="Store" min-width="100px" prop="name">
            <template v-slot="{ row }">
              <div class="d-flex">
                <img :src="row.image" class="avatar rounded-circle mr-3" />

                <span>
                  <b>{{ row.name }}</b>
                  <small class="d-block text-muted">ID: {{ row.id }}</small>
                </span>
                <span class="ml-3">
                  <small v-if="row.default" class="d-block text-muted"
                    >Default</small
                  >
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Location" prop="createdAt" min-width="100px">
            <template v-slot="{ row }">
              <span class="text-muted"
                >{{ row.address.city }} - {{ row.address.district }}</span
              >
            </template>
          </el-table-column>

          <el-table-column label="Tags" min-width="100px" prop="tags">
            <template v-slot="{ row }">
              <div class="avatar-group">
                <a
                  v-for="tag in row.tags"
                  href="#"
                  :key="tag.id"
                  class="avatar avatar-sm rounded-circle"
                  data-toggle="tooltip"
                  :data-original-title="tag.name"
                >
                  <img :alt="tag.name" :src="tag.icon" :title="tag.name" />
                </a>
              </div>
            </template>
          </el-table-column>

          <el-table-column min-width="180px" align="center">
            <template v-slot="{ row }">
              <div class="table-actions">
                <base-button type="primary" @click="onView(row)" size="sm">
                  <span class="btn-inner--icon"
                    ><i class="fas fa-eye"></i
                  ></span>
                </base-button>
                <base-button type="danger" @click="onDelete(row)" size="sm">
                  <span class="btn-inner--icon"
                    ><i class="fas fa-trash"></i
                  ></span>
                </base-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader.vue";
import { mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
  name: "Stores",
  data() {
    return {
      stores: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["userPlan"]),
  },
  methods: {
    getStores() {
      this.$api
        .get("/store?own=true")
        .then((response) => {
          this.stores = response.data.data.list;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onView(row) {
      this.$router.push(`/stores/view/${row.id}`);
    },
    onEdit(row) {
      alert(`You want to edit row ${row.id}`);
    },
    onDelete(row) {
      // Show Swal to confirm the deletion
      swal
        .fire({
          title: "Are you sure you want to delete this store?",
          text: `You won't be able to revert this, and all queues will be deleted!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Delete it",
          cancelButtonText: "Cancel",
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#2dce89",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$api.delete(`/store/${row.id}`).then(() => {
              this.getStores();
              this.$runToast(
                "top-right",
                "default",
                `Store <b>${row.name}</b> has been deleted successfully!`
              );
            });
          }
        });
    },
  },
  async mounted() {
    this.getStores();
  },
  components: {
    ElTableColumn,
    BaseHeader,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
