<script>
import BaseButton from "@/components/BaseButton.vue";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload.vue";
import TagsInput from "@/components/Inputs/TagsInput.vue";
import Modal from "@/components/Modal.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
export default {
  name: "QueueModal",
  props: {
    queue: {
      type: Object,
      default: null,
    },
  },
  components: {
    BaseButton,
    Form,
    DropzoneFileUpload,
    TagsInput,
    Modal,
    BaseInput,
  },
  data() {
    return {
      show: true,
      model_loading: false,
      model: {
        name: "",
        image: "",
        waiting_time: "",
        max_people: "",
        services: "",
      },
    };
  },
  mounted() {
    if (this.queue) {
      this.model = this.queue;
    }
    this.$refs.tagsInput.updateFromProps(this.model.services.split(","));
  },
  setup() {
    const schema = Yup.object().shape({
      QueueName: Yup.string().required().label("The Queue Name"),
      Image: Yup.string().required().label("The Queue Image").nullable(),
      WaitingTime: Yup.string()
        .required()
        .label("The Queue Waiting Time")
        .nullable(),
      MaxPeople: Yup.string()
        .required()
        .label("The Queue Waiting Time")
        .nullable(),
      Services: Yup.string().required().label("The Queue Services"),
    });
    return {
      schema,
    };
  },
  methods: {
    closeQueueModal() {
      this.$emit("close");
    },
    onSubmitQueue() {
      this.model_loading = true;
      let url = `/store/${this.$route.params.id}/queue`;
      let method = "post";
      if (this.model.id) {
        method = "put";
        url += `/${this.model.id}`;
      }
      this.model.waiting_time = this.model.waiting_time.toString();
      this.model.max_people = this.model.max_people.toString();
      this.$api[method](url, this.model)
        .then((response) => {
          const data = response.data.data;
          this.$runToast(
            "top-right",
            "default",
            `Queue <b>${data.name}</b> has been ${
              this.model.id ? "updated" : "created"
            } successfully with ID: <b>${data.id}</b> - Clients can join now!`
          );
          this.model_loading = false;
          this.$emit("close");
        })
        .catch((error) => {
          this.model_loading = false;
          this.$runToast("top-right", "error", error.response.data.message);
        });
    },

    updateImage(path) {
      this.model.image = path;
    },
  },
};
</script>

<template>
  <modal v-model:show="show" :size="'lg'" @close="closeQueueModal">
    <template v-slot:header>
      <h6 class="modal-title">Queue</h6>
    </template>
    <Form @submit="onSubmitQueue" :validation-schema="schema" ref="queueform">
      <div class="row">
        <div class="col-md-12">
          <base-input name="Image" v-model="model.image">
            <!--Card Body-->
            <dropzone-file-upload
              @path="updateImage"
              :path="model.image"
              ref="imageInput"
            ></dropzone-file-upload>
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input
            label="Queue Name"
            alternative
            name="QueueName"
            addon-left-icon="fas fa-users"
            placeholder="Indoor"
            v-model="model.name"
            :disabled="model_loading"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            label="Waiting Time"
            alternative
            name="WaitingTime"
            type="number"
            addon-left-icon="fas fa-hourglass-start"
            placeholder="10"
            v-model="model.waiting_time"
            :disabled="model_loading"
          >
            <template v-slot:infoBlock>
              <small class=""><b>Number in minutes</b></small>
            </template>
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input
            label="Max People"
            name="MaxPeople"
            type="number"
            alternative
            addon-left-icon="fas fa-user-plus"
            placeholder="1"
            v-model="model.max_people"
            :disabled="model_loading"
          >
            <template v-slot:infoBlock>
              <small class=""><b>If the are group</b></small>
            </template>
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            label="Services"
            name="Services"
            alternative
            @keydown.prevent.enter
            v-model="model.services"
            :disabled="model_loading"
          >
            <tags-input
              @updateText="model.services = $event"
              tagType="success"
              ref="tagsInput"
              placeholder="Heater, View ...."
              class="test"
            ></tags-input>
            <template v-slot:infoBlock>
              <small class=""><b>Write then press enter</b></small>
            </template>
          </base-input>
        </div>
      </div>

      <p>Once you creating queue clients can start enter it.</p>

      <div class="row m-1">
        <base-button
          type="primary"
          native-type="submit"
          :disabled="model_loading"
        >
          <span v-if="model.id">Update Queue</span>
          <span v-else>Create Queue</span>
        </base-button>
        <base-button type="link" class="ml-auto" @click="closeQueueModal"
          >Close</base-button
        >
      </div>
    </Form>
  </modal>
</template>

<style scoped></style>
