<template>
  <card type="gradient-primary">
    <payment-modal
      v-if="modals.payment"
      @close="closePaymentModal"
      :url="paymentURL"
    ></payment-modal>
    <div class="row justify-content-between align-items-center">
      <div class="col">
        <img
          src="img/icons/cards/visa1.png"
          alt="Visa"
          class="mr-lg-1"
          width="50"
        />
        <img
          src="img/icons/cards/mastercard1.png"
          alt="Mastercard"
          class="mr-lg-1"
          width="50"
        />
        <img
          src="img/icons/cards/mada.png"
          alt="Mada"
          class="mr-lg-1"
          width="50"
        />
      </div>
      <div class="col-auto">
        <div class="d-flex align-items-center">
          <small class="text-white font-weight-bold mr-3">Auto Renew</small>
          <base-switch type="white" v-model="card.auto_renew"></base-switch>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <Form
        role="form"
        class="form-secondary"
        @submit="onSubmit"
        :validation-schema="schema"
      >
        <base-input
          name="Name"
          :disabled="loading"
          addon-left-icon="ni ni-single-02"
          class="mb-3"
          placeholder="Name on card"
          v-model="card.name"
        >
        </base-input>

        <base-input
          name="Number"
          :disabled="loading"
          addon-left-icon="ni ni-credit-card"
          class="mb-3"
          placeholder="Card number"
          v-model="card.number"
        >
        </base-input>
        <div class="row">
          <div class="col-6">
            <base-input
              name="Expire"
              :disabled="loading"
              p-icon="ni ni-calendar-grid-58"
              class="mb-3"
              placeholder="MM/YY"
              v-model="card.expire"
            >
            </base-input>
          </div>
          <div class="col-6">
            <base-input
              name="cvc"
              :disabled="loading"
              addon-left-icon="ni ni-lock-circle-open"
              class="input-group-alternative mb-3"
              placeholder="CCV"
              v-model="card.cvc"
            >
            </base-input>
          </div>
        </div>
        <base-button native-type="submit" type="info" block :loading="loading"
          >Pay ({{ amount }} SAR)</base-button
        >
      </Form>
    </div>
  </card>
  <div class="d-flex justify-content-lg-center align-content-center px-3 mt-5">
    <div class="">
      <div
        class="icon icon-shape bg-gradient-white shadow rounded-circle text-primary"
      >
        <i class="ni ni-building text-primary"></i>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="text-primary"><strong>Secure</strong> payment.</div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
import { Form } from "vee-validate";
import PaymentModal from "@/views/Subsrcription/component/paymentModal.vue";
import QueueModal from "@/views/Store/components/queue.vue";
export default {
  name: "master-card",
  components: {
    PaymentModal,
    Form,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    plan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      paymentURL: "",
      paymentID: "",
      modals: {
        payment: false,
      },
      loading: false,
      validated: false,
      card: {
        auto_renew: true,
        name: "",
        number: "",
        expire: "",
        cvc: "",
      },
    };
  },
  watch: {
    // Watch card number and add space after every 4 digits  and max 16 digits
    "card.number": function (val) {
      let cardNumber = val.replace(/\D/g, "");

      // Add space after every 4 digits
      cardNumber = cardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");

      // Update the input value
      this.card.number = cardNumber.slice(0, 19);
    },
    // Watch card expire and add slash after every 2 digits and max two digits
    "card.expire": function (val) {
      let cardExpire = val.replace(/\D/g, "");

      // Add slash after every 2 digits
      cardExpire = cardExpire.replace(/(\d{2})(?=\d)/g, "$1/");

      // Update the input value
      this.card.expire = cardExpire.slice(0, 5);
    },
    // CVC max 3 digits
    "card.cvc": function (val) {
      let cardCvc = val.replace(/\D/g, "");
      this.card.cvc = cardCvc.slice(0, 3);
    },
  },
  methods: {
    closePaymentModal() {
      this.modals.payment = false;
      this.$api
        .get(`payment/${this.paymentID}`)
        .then((res) => {
          this.$runToast("top-right", "success", "Payment successful");
          this.$emit("checkPayment", res.data.data); // checkPayment
        })
        .catch((err) => {
          this.loading = false;
          this.$runToast("top-right", "error", err.response.data.data.error);
        });
    },
    async createPayment(data) {
      await this.$api
        .post("payment", {
          plan_id: this.plan.id,
          token: data.id,
          auto_renew: this.card.auto_renew,
        })
        .then((initResponse) => {
          this.paymentURL = initResponse.data.data.secure_url;
          this.paymentID = initResponse.data.data.payment_id;
          this.modals.payment = true;
        })
        .catch((err) => {
          this.catchPaymentErrors(err, "data");
        });
    },
    onSubmit() {
      this.loading = true;
      let data = {
        callback_url: process.env.VUE_APP_API_URL + "/subscriptions",
        publishable_api_key: process.env.VUE_APP_MOYASAR_KEY,
        name: this.card.name,
        number: this.card.number.replace(/\s/g, ""),
        month: this.card.expire.split("/")[0],
        year: this.card.expire.split("/")[1],
        cvc: this.card.cvc,
        save_only: "true",
      };
      this.$api
        .post("https://api.moyasar.com/v1/tokens", data)
        .then(async (res) => {
          const data = res.data;
          await this.createPayment(data);
        })
        .catch((err) => {
          this.catchPaymentErrors(err);
        });
    },
    catchPaymentErrors(err, keyErrors = "errors") {
      const error = err.response.data;
      let message = error.message + "\n";
      for (const [key, value] of Object.entries(error[keyErrors])) {
        message += key + ": " + value + "\n";
      }
      this.loading = false;
      this.$runToast("top-right", "error", message);
    },
  },
  setup() {
    const schema = Yup.object().shape({
      Name: Yup.string().required(),
      Number: Yup.string().required(),
      Expire: Yup.string().required(),
      cvc: Yup.string().required(),
    });

    return {
      schema,
    };
  },
};
</script>
