<template>
  <ul class="list-group list-group-flush list my--3">
    <li
      class="list-group-item px-0"
      v-for="(user, index) in users"
      :key="user.user_id"
    >
      <div class="row align-items-center">
        <div class="col-auto">
          <!-- Avatar -->
          <a href="#" class="avatar rounded-circle">
            <img alt="Image placeholder" src="img/theme/team-1.jpg" />
          </a>
        </div>
        <div class="col ml--2">
          <h4 class="mb-0">
            <small>{{ user.user_id }}</small> -
            <a href="#!">{{ user.user_name }}</a>
          </h4>
          <h4 class="mb-0">
            <small>{{ user.user_phone }}</small>
          </h4>
          <span :class="`text-${user.user_connected ? 'success' : 'danger'}`"
            >●
          </span>
          <small>{{ user.connection_id || "Offline" }}</small>
          <div class="col ml--2">
            <base-button type="info" size="sm"
              >People: {{ user.number_of_people }}</base-button
            >
            <base-button type="warning" size="sm"
              >Order: {{ user.user_order }}</base-button
            >
          </div>
        </div>
        <div class="col-auto">
          <base-button
            v-if="index === 0"
            type="primary"
            size="sm"
            @click="came(user.user_id)"
            >Arrived</base-button
          >
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: "user-list",
  props: {
    users: {
      type: Array,
      required: true,
    },
    queue_id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    came(user_id) {
      console.log("came", user_id);
      this.$api
        .post("admin_p/socket/arriveUser", {
          user_id: user_id,
          queue_id: this.queue_id,
        })
        .then((res) => {
          this.$emit("updateList");
          console.log(res);
        });
    },
  },
  data() {
    return {
      old: [
        {
          id: 1,
          img: "img/theme/team-1.jpg",
          name: "John Michael",
          status: "Online",
          statusType: "success",
        },
        {
          id: 2,
          img: "img/theme/team-2.jpg",
          name: "Alex Smith",
          status: "In a meeting",
          statusType: "danger",
        },
        {
          id: 3,
          img: "img/theme/team-3.jpg",
          name: "Samantha Ivy",
          status: "Offline",
          statusType: "danger",
        },
        {
          id: 4,
          img: "img/theme/team-4.jpg",
          name: "John Michael",
          status: "Online",
          statusType: "success",
        },
      ],
    };
  },
};
</script>
<style></style>
