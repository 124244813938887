<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4"
          ></nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>

    <!-- Contact card -->
    <div class="container-fluid mt--6">
      <div class="row justify-content-center">
        <div class="col-lg-3 card-wrapper">
          <!-- Grid system -->
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <h3 class="mb-0">Account Information</h3>
            </div>
            <!-- Card body -->
            <div class="card-body">
              <Form @submit="onSubmit" :validation-schema="schema">
                <base-input
                  :disabled="loading"
                  name="name"
                  label="Name"
                  addon-left-icon="fa fa-user-circle"
                  v-model="model.name"
                  placeholder="Sraa App"
                />

                <base-input
                  :disabled="loading"
                  name="phone"
                  inputmode="numeric"
                  label="Phone"
                  addon-left-icon="fa fa-phone"
                  placeholder="0599999999"
                  v-model="model.phone"
                >
                </base-input>
                <small class="font-weight-bolder text-danger">{{
                  error_message
                }}</small>
                <base-button
                  type="success"
                  native-type="submit"
                  class="my-4"
                  :disabled="loading"
                  >Submit</base-button
                >
              </Form>
            </div>
          </div>
        </div>
        <div class="col-lg-3 card-wrapper">
          <!-- Grid system -->
          <card class="bg-gradient-primary">
            <div class="row justify-content-center mt-5">
              <div class="">
                <h5
                  class="card-title text-uppercase text-muted mb-0 text-white"
                >
                  Stores
                </h5>
                <span class="h2 font-weight-bold mb-0 text-white">1/2</span>
              </div>
            </div>
            <div class="row justify-content-center mt-3">
              <div class="">
                <img src="/img/landing/waiting-in-line.svg" width="250px" />
              </div>
            </div>
            <!-- Card body -->

            <p class="mt-3 mb-0 text-sm">
              <span class="text-white mr-2"></span>
            </p>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";

import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BaseHeader from "@/components/BaseHeader.vue";
import * as Yup from "yup";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Information",
  data() {
    return {
      model: {},
      error_message: "",
      loading: false,
    };
  },
  computed: {
    ...mapState(["count"]),
  },
  methods: {
    ...mapMutations(["increment", "decrement"]),
    ...mapActions(["userInformation"]),
    onSubmit() {
      this.$api
        .post("/merchant/information", this.model)
        .then(() => {
          this.userInformation();
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.error_message = error.response.data.message;
          console.log(error);
        });
    },
  },
  async mounted() {},
  setup() {
    const schema = Yup.object().shape({
      phone: Yup.string()
        .min(10, "Phone should be 10 Digits")
        .max(10, "Phone should be 10 Digits")
        .required()
        .label("Phone"),
      name: Yup.string().required().label("Name"),
    });

    return {
      schema,
    };
  },
  components: {
    BaseInput,
    Form,
    BaseHeader,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
