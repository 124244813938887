<template>
  <div class="row justify-content-md-center mt-2" dir="ltr" id="otp-box">
    <input
      v-for="(item, index) in otp"
      :key="index"
      style="width: 40px; text-align: center"
      autocomplete="off"
      :disabled="loading"
      class="form-control mr-2"
      type="text"
      :data-index="`${index + 1}`"
      :id="`otp_${index + 1}`"
      inputmode="numeric"
      @input="digitValidate(index)"
      v-model="otp[index]"
      autofocus
      maxlength="1"
    />
  </div>
</template>

<script>
export default {
  name: "OtpInput",
  props: ["value", "loading", "digitCount"],
  data() {
    return {
      otp: [],
    };
  },
  watch: {
    // watch otp deep
    otpValue(newVal, oldVal) {
      let otp = newVal.join("");
      this.$emit("updateOTP", otp);
      if (newVal[this.digitCount - 1] !== oldVal[this.digitCount - 1]) {
        if (otp.length === this.digitCount) {
          this.$emit("submit");
        }
      }
    },
  },
  mounted() {
    // set otp length to digit-count
    if (this.digitCount) {
      this.otp = new Array(this.digitCount);
    }
    document
      .getElementById("otp-box")
      .addEventListener("keydown", this.backSpace);
    document.getElementById("otp-box").addEventListener("paste", this.paste);
  },
  beforeUnmount() {
    // Remove Events
    if (document.getElementById("otp-box") !== null) {
      document
        .getElementById("otp-box")
        .removeEventListener("keydown", this.backSpace);
      document
        .getElementById("otp-box")
        .removeEventListener("paste", this.paste);
    }
  },
  computed: {
    otpValue() {
      return JSON.parse(JSON.stringify(this.otp));
    },
  },
  methods: {
    paste(event) {
      // paste event
      event.preventDefault();
      let text = event.clipboardData.getData("text/plain");
      let otp = text.split("");
      otp = otp.map((e) => parseInt(e)).filter((e) => !isNaN(e));
      if (otp.length === this.digitCount) {
        this.otp = otp;
      }
    },
    backSpace(event) {
      let index = event.srcElement.getAttribute("data-index");
      if (event.keyCode == 8) {
        if (this.otp[index - 1]) {
          this.otp[index - 1] = "";
        } else {
          if (index !== 0)
            document.getElementById("otp_" + (index - 1)).focus();
        }
      }
    },
    digitValidate(e) {
      this.otp[e] = this.otp[e].replace(/[^0-9]/g, "");
      if (this.otp[e].length > 1) {
        this.otp[e].slice(0, e.maxLength);
      }
      if (this.otp[e].length === 1) {
        if (e !== this.digitCount - 1)
          // Focus seconds input
          document.getElementById("otp_" + (e + 2)).focus();
      }
    },
  },
};
</script>

<style scoped></style>
