<template>
  <div>
    <div style="position: absolute; z-index: 1000; width: 100%" class="m-3">
      <div class="row">
        <!--        <div class="col-4" style="z-index: inherit">-->

        <!--          <base-input name="select"   v-model="search">-->

        <!--&lt;!&ndash;            Select  search&ndash;&gt;-->
        <!--            <el-select-->
        <!--              v-model="search"-->
        <!--              multiple-->
        <!--              filterable-->
        <!--              remote-->
        <!--              reserve-keyword-->
        <!--              placeholder="Please enter a keyword"-->
        <!--              :remote-method="remoteSearch"-->
        <!--              :loading="loadingSearch"-->
        <!--            >-->
        <!--              <el-option-->
        <!--                v-for="item in searchResults"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value"-->
        <!--              />-->
        <!--            </el-select>-->
        <!--          </base-input>-->
        <!--        </div>-->
        <div class="col-3">
          <base-button @click="mylocation" type="primary" size="sm"
            >Go My Location</base-button
          >
        </div>
      </div>
    </div>

    <div id="map" class="map-canvas" style="height: 320px"></div>
  </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";

import BaseButton from "@/components/BaseButton.vue";
// import BaseInput from "@/components/Inputs/BaseInput.vue";
import { ElSelect } from "element-plus";

export default {
  props: {
    lat: {
      type: Number,
      default: 24.7248315,
    },
    lng: {
      type: Number,
      default: 46.4928723,
    },
  },
  components: {
    // BaseInput,
    BaseButton,
    [ElSelect.name]: ElSelect,
  },
  data() {
    return {
      loadingSearch: false,
      value: "",
      searchResults: [],
      search: "",
      loader: null,
      map: null,
      marker: null,
    };
  },
  methods: {
    remoteSearch(query) {
      if (query !== "") {
        this.loadingSearch = true;
        const request = {
          query: query,
          fields: ["name", "geometry"],
        };
        console.log(this.searchResults);
        const service = new google.maps.places.PlacesService(this.map);
        service.findPlaceFromQuery(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            console.log(this.searchResults);
            this.searchResults = [];
            results.forEach((item) => {
              console.log(item.name);
              this.searchResults.push({
                label: item.name,
                value: item.name,
              });
            });

            console.log(results);
            // this.options = this.selectOptions.filter((item) => {
            //   return item.label.toLowerCase()
            //     .includes(query.toLowerCase());
            // });
          } else {
            this.searchResults = [];
          }
          this.loadingSearch = false;
        });
      }
    },
    mylocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const currentLatLng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.map.setCenter(currentLatLng);
            this.marker.setPosition(currentLatLng);
            // Set  zoom 15
            this.map.setZoom(18);
          },
          (error) => {
            console.error("Error getting current location:", error.message);
          }
        );
      } else {
        alert("Geolocation is not supported by your browser");
      }
    },
    getAddressComponent(place, componentType) {
      const foundComponent = place.address_components.find((component) =>
        component.types.includes(componentType)
      );

      return foundComponent ? foundComponent.long_name : "";
    },
    reverseGeocode(latLng) {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            console.log(results[0]);
            const city = this.getAddressComponent(results[0], "locality");
            const district = this.getAddressComponent(results[0], "political");

            console.log("City:", city);
            console.log("District:", district);
            this.$emit("change", {
              lat: latLng.lat(),
              lng: latLng.lng(),
              city: city,
              district: district,
            });
          }
        } else {
          console.error("Geocoder failed due to:", status);
        }
      });
    },
    updateLocation(currentLatLng) {
      this.map.setCenter(currentLatLng);
      this.marker.setPosition(currentLatLng);
      // Zoom 15
      this.map.setZoom(18);
    },
  },
  mounted() {
    const loader = new Loader({
      apiKey: "AIzaSyAS0KU-p_S3UedpbUVXH1WH5Z4Fl2f_v64",
      version: "weekly",
      libraries: ["places"],
    });
    loader.load().then(() => {
      // Regular Map
      const defaultLocation = new google.maps.LatLng(this.lat, this.lng);
      const mapOptions = {
        zoom: 11,
        center: defaultLocation,
        scrollwheel: true, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
          },
        ],
      };
      this.map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );

      const customIcon = {
        url: "/img/icons/store-solid.svg", // Replace with the path to your custom icon
        scaledSize: new google.maps.Size(40, 40), // Adjust the size as needed
      };
      this.marker = new google.maps.Marker({
        position: defaultLocation,
        map: this.map,
        icon: customIcon,
        draggable: true, // Allow the marker to be draggable
      });
      google.maps.event.addListener(this.marker, "dragend", (event) => {
        this.reverseGeocode(event.latLng);
      });

      // On user finished moving the  map
      google.maps.event.addListener(this.map, "idle", () => {
        const currentLatLng = this.map.getCenter();
        this.reverseGeocode(currentLatLng);
        this.marker.setPosition(currentLatLng);
      });

      this.marker.setMap(this.map);
    });
  },
};
</script>
