const pointerScroll = (elem) => {
  let isDrag = false;

  const dragStart = () => (isDrag = true);
  const dragEnd = () => (isDrag = false);
  const drag = (ev) => isDrag && (elem.scrollLeft -= ev.movementX);

  elem.addEventListener("pointerdown", dragStart);
  addEventListener("pointerup", dragEnd);
  addEventListener("pointermove", drag);
  console.log("pointerScroll");
};

const GlobalHelpers = {
  install(app) {
    app.config.globalProperties.$pointerScroll = pointerScroll;
  },
};
export default GlobalHelpers;
