import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "@/views/Layout/DashboardLayout";
// Dashboard pages
import Dashboard from "../views/Dashboard/index.vue";

// Pages
import Login from "../views/Pages/Login.vue";


import InQueue from "@/views/Queue/InQueue.vue";
import Users from "@/views/UserManagement/Users.vue";
import QueueManagment from "@/views/Queue/QueueManagment.vue";
import Subscriptions from "@/views/Subsrcription/Subscriptions.vue";
import Store from "@/views/Store/index.vue";
import CreateStore from "@/views/Store/create.vue";
import ViewStore from "@/views/Store/view.vue";
import Landing from "@/views/Landing/index.vue";
import MainLayout from "@/views/Layout/MainLayout.vue";
import Terms from "@/views/Pages/Terms.vue";
import NotFound from "@/views/Pages/NotFound.vue";


const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "Landing",
        components: { default: Landing },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next("/login");
      }
      next();
    },
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/dashboard/stores",
        name: "Store",
        components: { default: Store },
      },
      {
        path: "/stores/create",
        name: "CreateStore",
        components: { default: CreateStore },
      },
      {
        path: "/stores/edit/:id",
        name: "EditStore",
        components: { default: CreateStore },
      },
      {
        path: "/stores/view/:id",
        name: "ViewStore",
        components: { default: ViewStore },
      },
      {
        path: "/queue",
        name: "Queue",
        components: { default: InQueue },
      },
      {
        path: "/queue/list",
        name: "QueueManagement",
        components: { default: QueueManagment },
      },
      {
        path: "/users",
        name: "Users",
        components: { default: Users },
      },
      {
        path: "/subscriptions",
        name: "Subscriptions",
        components: { default: Subscriptions },
      },
    ],
  },
  {
    path: "/",
    redirect: "/",
    component: MainLayout,
    children: [
      // {
      //   path: "/pricing",
      //   name: "Pricing",
      //   components: { default: Pricing },
      // },
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
        beforeEnter: (to, from, next) => {
          const token = localStorage.getItem("token");
          if (!token) {
            next();
          }
          next("/dashboard");
        },
      },
      {
        path: "/terms",
        name: "Terms",
        components: { default: Terms },
      },
      { path: "/:pathMatch(.*)*", component: NotFound },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    document.querySelector("body").scrollIntoView({ behavior: "smooth" });
  },
});

export default router;
