<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>

    <!-- Contact card -->
    <div class="container-fluid mt--6">
      <Form @submit="onSubmit" :validation-schema="schema">
        <div class="row">
          <div class="col-lg-12">
            <base-input name="Location" v-model="model.lat">
              <google-maps @change="updateLocation" ref="storeMap" />
            </base-input>
          </div>
        </div>
        <div class="row row-eq-height">
          <div class="col-md-6">
            <card class="pb-5">
              <!-- Card header -->
              <template v-slot:header>
                <h3 class="mb-0">Store Information</h3>
              </template>
              <!-- Card body -->

              <!-- Input groups with icon -->
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    alternative
                    label="Store Name"
                    name="StoreName"
                    addon-left-icon="fas fa-store"
                    placeholder="Happy Store"
                    v-model="model.name"
                    :disabled="loading"
                  >
                  </base-input>
                </div>
                <div class="col-md-6">
                  <base-input
                    name="StoreTags"
                    label="Store Tags"
                    :disabled="loading"
                    v-model="model.tags"
                  >
                    <el-select
                      v-model="model.tags"
                      multiple
                      filterable
                      placeholder="Restaurant, Cafe, ..."
                    >
                      <el-option
                        v-for="option in tags"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      >
                        <img
                          :src="option.icon"
                          alt="icon"
                          style="width: 20px; height: 20px"
                        />
                        <span class="ml-1">{{ option.name }}</span>
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <!-- Input groups with icon -->
            </card>
          </div>
          <div class="col-md-6">
            <card>
              <!--Card Header-->
              <template v-slot:header>
                <h3 class="mb-0">Your Store Image</h3>
              </template>
              <base-input name="Image" v-model="model.image">
                <!--Card Body-->
                <dropzone-file-upload
                  @path="updateImage"
                  ref="storeImage"
                  :path="model.image"
                ></dropzone-file-upload>
              </base-input>
            </card>
          </div>
        </div>

        <base-button type="primary" native-type="submit" :disabled="loading">
          {{ isEdit ? "Update" : "Create Store" }}
        </base-button>
      </Form>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload.vue";
import GoogleMaps from "@/views/Maps/GoogleMaps.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";

export default {
  name: "CreateStore",
  data() {
    return {
      isEdit: false,
      loadingEdit: true,
      storeId: null,
      loading: false,
      tags: [],
      validated: false,
      model: {
        name: "",
        tags: [],
        image: "",
        lat: 0,
        lng: 0,
        city: "",
        district: "",
      },
    };
  },
  setup() {
    const schema = Yup.object().shape({
      StoreName: Yup.string().required().label("The Store Name"),
      StoreTags: Yup.array()
        .required()
        .min(1, "dw")
        .label("The Store Tags")
        .nullable(),
      Image: Yup.string().required().label("The Store Image").nullable(),
      Location: Yup.string().required().label("The Store Location"),
    });
    return {
      schema,
    };
  },
  methods: {
    getTags() {
      this.$api
        .get("/tag")
        .then((response) => {
          this.tags = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateImage(file) {
      this.model.image = file;
    },
    updateLocation(location) {
      this.model.lat = location.lat;
      this.model.lng = location.lng;
      this.model.city = location.city;
      this.model.district = location.district;
    },
    onSubmit() {
      this.loading = true;
      this.validated = true;
      const method = this.isEdit ? "put" : "post";
      const path = this.isEdit ? `/store/${this.storeId}` : "/store";
      this.$api[method](path, this.model).then((response) => {
        const data = response.data.data;
        this.loading = false;
        this.$router.push("/dashboard/stores");
        let message = `Store <b>${data.name}</b> has been created successfully with ID: <b>${data.id}</b> - You can add queues for this store now!`;
        if (this.isEdit) {
          message = `Store <b>${data.name}</b> has been updated successfully`;
        }
        this.$runToast("top-right", "default", message);
      });
    },
    getStore() {
      this.loadingEdit = true;
      this.$api
        .get("/store/" + this.$route.params.id)
        .then((response) => {
          const data = response.data.data;
          this.model = {
            name: data.name,
            tags: data.tags.map((tag) => tag.id),
            image: data.image,
            lat: data.address.lat,
            lng: data.address.lng,
            city: data.address.city,
            district: data.address.district,
          };
          this.$refs.storeMap.updateLocation({
            lat: data.address.lat,
            lng: data.address.lng,
            city: data.address.city,
            district: data.address.district,
          });
          this.$refs.storeImage.updateImage(data.image);
          this.loadingEdit = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    //  Is Edit?
    const name = this.$route.name;
    if (name === "EditStore") {
      this.isEdit = true;
      this.storeId = this.$route.params.id;
      this.getStore();
    }
    await this.getTags();
    // Check
  },
  components: {
    ElOption,
    BaseInput,
    GoogleMaps,
    DropzoneFileUpload,
    Form,
    BaseHeader,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
};
</script>
