<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Queue Management</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
      <!-- Card stats -->
    </base-header>
    <div class="container-fluid mt--6">
      <!--Lists-->
      <div class="row">
        <div class="col">
          <queue-list-table />
        </div>
      </div>
      <!--End lists-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import QueueListTable from "@/views/Queue/QueueList.vue";
let chart;

export default {
  components: {
    QueueListTable,
    RouteBreadCrumb,
  },
  data() {
    return {
      queues: [],
    };
  },
  methods: {
    information() {
      this.$api.get("admin_p/socket/information").then((res) => {
        this.queues = res.data.data;
      });
    },
    initBigChart(index) {
      chart.update(
        (chart.config.data.datasets[0].data = this.bigLineChart.allData[index])
      );
      this.bigLineChart.activeIndex = index;
    },
  },
  mounted() {},
};
</script>

<style></style>
