import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

import "../assets/sass/argon.scss";
import SidebarPlugin from "../components/SidebarPlugin";

import "bootstrap/dist/js/bootstrap.min.js";
import GlobalMethods from "@/plugins/globalNotification";
import GlobalHelpers from "@/plugins/globalFunctions";

export default {
  install(app) {
    app.use(SidebarPlugin);
    app.use(GlobalComponents);
    app.use(GlobalMethods);
    app.use(GlobalDirectives);
    app.use(GlobalHelpers);
  },
};
