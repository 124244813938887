import { createStore } from "vuex";
import app from "@/main";

const store = createStore({
  state: {
    // Your application state goes here
    user: null,
    subscription: null,
    needInformation: false,
  },
  mutations: {
    // Mutations are responsible for changing the state
    saveUser(state, payload) {
      state.user = payload;
    },
    saveSubscription(state, payload) {
      state.subscription = payload;
    },
    needInformation(state, payload) {
      state.needInformation = payload;
    },
  },
  actions: {
    async userInformation({ commit }) {
      // Load axios from vue
      try {
        const res = await app.axiosInstance.get("/merchant/information");
        const response = res.data.data;
        if (response.user.name === null || response.user.name === "") {
          commit("needInformation", true);
        } else {
          commit("needInformation", false);
        }
        commit("saveUser", response.user);
        commit("saveSubscription", response.subscription);
      } catch (err) {
        console.log(err);
      }
    },
  },
  getters: {
    // Getters are used to retrieve state data with computed properties
    getNeedInformation: (state) => state.needInformation,
    user: (state) => state.user,
    userSubscription: (state) => state.subscription,
    userPlan: (state) => state.subscription?.plan,
  },
});

export default store;
