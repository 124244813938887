import Notification from "@/components/Notification.vue";
import { useToast } from "vue-toastification";
function runToast(pos, type, ownText = "", ownIcon = "") {
  const text =
    "Welcome to <b>Sraa App</b> - a beautiful resource for every web developer";
  const icon = "fas fa-bell";
  const content = {
    component: Notification,
    props: {
      ownText: ownText,
      ownIcon: ownIcon,
      icon: icon,
      text: text,
      type: type,
    },
  };
  const toast = useToast();
  toast(content, {
    hideProgressBar: true,
    icon: false,
    closeButton: false,
    position: pos,
  });
}

const GlobalMethods = {
  install(app) {
    app.config.globalProperties.$runToast = runToast;
  },
};
export default GlobalMethods;
