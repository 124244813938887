<template>
  <div class="content">
    <queue-modal
      v-if="modals.queue"
      @close="closeQueueModal"
      :queue="model"
    ></queue-modal>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>

    <!-- Contact card -->
    <div class="container-fluid mt--6" v-if="store">
      <card>
        <!-- Card body -->
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- Avatar -->
            <i
              size="xl"
              class="avatar avatar-xl rounded-circle fa fa-store bg-primary"
            />
          </div>
          <div class="col ml--2">
            <h4 class="mb-0">
              <a href="#!">Edit Store</a>
            </h4>
          </div>
          <div class="col-auto">
            <base-button
              type="primary"
              size="lg"
              :icon="true"
              @click="
                () => {
                  $router.push('/stores/edit/' + $route.params.id);
                }
              "
            >
              <span>Edit</span>
              <i class="fa fa-store"></i>
            </base-button>
          </div>
        </div>
      </card>

      <div class="row">
        <div class="col-lg-4">
          <card>
            <!-- Card body -->
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <img
                  :src="store.image"
                  class="avatar avatar-xl rounded-circle"
                />
              </div>
              <div class="col ml--2">
                <h4 class="mb-0">
                  <a href="#!">{{ store.name }}</a>
                </h4>
                <p class="text-sm text-muted mb-0">
                  {{ store.address.city }} - {{ store.address.district }}
                </p>
              </div>
              <div class="col-auto">
                <!--                  Tags-->
              </div>
            </div>
          </card>
          <card>
            <!-- Card body -->
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <base-button
                  v-for="tag in store.tags"
                  :key="tag.id"
                  size="sm"
                  type="secondary"
                >
                  <span class="mr-2">
                    <img
                      :src="tag.icon"
                      class="avatar avatar-mini rounded-circle"
                      :alt="tag.name"
                    />
                  </span>
                  <span class="btn-inner--text">{{ tag.name }}</span>
                </base-button>
              </div>
            </div>
          </card>
          <iframe
            :src="`https://maps.google.com/maps?q=${store.address.lat},${store.address.lng}&hl=en&z=14&amp;output=embed`"
            width="100%"
            height="350"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div class="col-lg-8">
          <card v-if="userPlan">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col ml--2">
                  <h4 class="mb-0">
                    <a href="#!">Queues</a>
                  </h4>
                  <span
                    :class="{
                      'text-danger':
                        userPlan.number_of_queues_per_store - queues?.length <=
                        0,
                      'text-success':
                        userPlan.number_of_queues_per_store - queues?.length >
                        0,
                    }"
                    >●
                  </span>
                  <small
                    >{{
                      userPlan.number_of_queues_per_store - queues.length
                    }}
                    Queues remaining</small
                  >
                </div>
                <div class="col-auto">
                  <base-button
                    :disabled="
                      userPlan.number_of_queues_per_store - queues.length <= 0
                    "
                    type="primary"
                    size="lg"
                    :icon="true"
                    @click="modals.queue = true"
                  >
                    <span>Create</span>
                    <i class="fa fa-arrow-right"></i>
                  </base-button>
                </div>
              </div>
            </template>
            <ul class="list-group list-group-flush list my--3">
              <li
                class="list-group-item px-0"
                v-for="item in queues"
                :key="item.id"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img
                      :src="item.image"
                      class="avatar avatar-md rounded-circle"
                    />
                  </div>
                  <div class="col">
                    <span class="mb-3">
                      <b class="mr-2">{{ item.name }} </b>
                      <small class="mr-2">{{
                        item.default ? "(Default)" : ""
                      }}</small>
                      <base-button
                        v-for="service in item.services.split(',')"
                        :key="service"
                        size="sm"
                        type="info"
                      >
                        <span class="btn-inner--text">{{ service }}</span>
                      </base-button>
                    </span>
                    <base-progress
                      class="mb-0"
                      :showLabel="true"
                      :type="'info'"
                      :label="'People in queue: ' + item.people_enqueue"
                      :value="
                        (
                          (item.people_enqueue /
                            userPlan.max_number_of_waiting_people_per_queue) *
                          100
                        ).toFixed(2)
                      "
                    >
                    </base-progress>
                  </div>
                  <div class="col-auto">
                    <div class="row">
                      <div class="col-auto">
                        <base-button
                          type="warning"
                          size="sm"
                          @click="onManageQueue(item)"
                        >
                          <span class="btn-inner--icon"
                            ><i class="fas fa-edit"></i
                          ></span>
                        </base-button>
                        <base-button
                          type="danger"
                          size="sm"
                          @click="onDeleteQueue(item)"
                        >
                          <span class="btn-inner--icon"
                            ><i class="fas fa-trash"></i
                          ></span>
                        </base-button>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-auto">
                        <el-switch
                          v-model="item.is_closed"
                          class="p-2"
                          active-color="#f5365c"
                          inactive-color="#2dce89"
                          inline-prompt
                          active-text="Closed"
                          inactive-text="Open"
                          @change="updateQueueStatus(item.id, item.is_closed)"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="(inQueues[item.id]?.users ?? []).length === 0"
                  class="row justify-content-center queue-online-box"
                >
                  <img src="/img/icons/cards/sraa--empty.gif" width="150" />
                </div>
                <div
                  class="row flex-nowrap queue-online-box"
                  v-if="(inQueues[item.id]?.users ?? []).length > 0"
                >
                  <stats-card
                    class="mt-1 mb-1 mr-2 col-sm-4 queue-online-card"
                    v-for="user in inQueues[item.id]?.users ?? []"
                    :key="user.user_id"
                    :title="user.order === 0 ? 'Current Turn' : 'Waiting'"
                    type="gradient-red"
                    icon="ni ni-active-40"
                  >
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      {{ user.order === 0 ? "Current Turn" : "Waiting" }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      user.name
                    }}</span>
                    <div>
                      <span class="text-info">● </span>
                      <small> {{ $filters.PhoneNumber(user.phone) }}</small>
                    </div>

                    <template v-slot:icon>
                      <el-tag class="mr--1" effect="info">
                        {{ "Order" }}
                      </el-tag>
                      <div
                        class="icon icon-shape text-white rounded-circle shadow"
                        :class="['bg-danger', 'iconClasses']"
                      >
                        {{ user.order + 1 }}
                      </div>
                    </template>
                    <template v-slot:footer>
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <span class="text-success mr-2 font-weight-bold"
                              ><i class="fas fa-users mr-1"></i
                              >{{ user.number_of_people }}</span
                            >
                            <span class="text-nowrap">Number Of People</span>
                          </div>
                          <div class="row">
                            <span
                              class="mr-2 font-weight-bold"
                              :class="{
                                'text-success':
                                  $filters.MinutesAgo(user.joined_at) <
                                  item.waiting_time,
                                'text-warning':
                                  $filters.MinutesAgo(user.joined_at) ===
                                  item.waiting_time,
                                'text-danger':
                                  $filters.MinutesAgo(user.joined_at) >
                                  item.waiting_time,
                              }"
                              ><i class="fas fa-hourglass-half mr-1"></i
                              >{{ $filters.MinutesAgo(user.joined_at) }}</span
                            >
                            <span class="text-nowrap">Waiting minutes</span>
                          </div>
                        </div>
                        <div class="col-auto">
                          <el-popconfirm
                            v-if="user.order === 0"
                            confirm-button-text="Yes"
                            :hide-icon="true"
                            :width="250"
                            :confirm-button-type="'info'"
                            cancel-button-text="No"
                            icon-color="#626AEF"
                            title="Do you want to arrive this user?"
                            @confirm="arriveUser(item.id, user.user_id)"
                          >
                            <template #reference>
                              <base-button type="info" size="sm"
                                >Arrive</base-button
                              >
                            </template>
                          </el-popconfirm>
                        </div>
                      </div>
                    </template>
                  </stats-card>
                </div>
              </li>
            </ul>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElSwitch,
  ElTag,
  ElPopconfirm,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseProgress from "@/components/BaseProgress.vue";
import swal from "sweetalert2";
import QueueModal from "@/views/Store/components/queue.vue";
import { mapGetters } from "vuex";
import Card from "@/components/Cards/Card.vue";
import StatsCard from "@/components/Cards/StatsCard.vue";
const modelDefault = {
  name: "",
  image: "",
  waiting_time: "",
  max_people: "",
  services: "",
};
export default {
  name: "ViewStore",
  data() {
    return {
      interval: null,
      modals: {
        queue: false,
      },
      model_loading: false,
      model: null,
      store: null,
      queues: [],
      inQueues: {},
    };
  },
  computed: {
    ...mapGetters(["userPlan"]),
  },
  beforeMount() {
    this.model = JSON.parse(JSON.stringify(modelDefault));
  },
  methods: {
    onManageQueue(item) {
      this.modals.queue = true;
      this.model = JSON.parse(JSON.stringify(item));
    },
    onDeleteQueue(item) {
      // Show Swal to confirm the deletion
      swal
        .fire({
          title: "Are you sure you want to delete this queue?",
          text: `You won't be able to revert this, and all current clients will be kicked from this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Delete it",
          cancelButtonText: "Cancel",
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#2dce89",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$api
              .delete(`/store/${this.$route.params.id}/queue/${item.id}`)
              .then(() => {
                this.getQueues();
                this.$runToast(
                  "top-right",
                  "default",
                  `Queue <b>${item.name}</b> has been deleted successfully!`
                );
              });
          }
        });
    },
    arriveUser(queueId, userId) {
      this.$api
        .post(`/store/${this.$route.params.id}/queue/${queueId}/arrive`, {
          user_id: userId,
        })
        .then(() => {
          this.getInQueueDetails(queueId);
        });
    },
    updateQueueStatus(queueId, status) {
      this.$api
        .post(`/store/${this.$route.params.id}/queue/${queueId}/status`, {
          closed: status,
        })
        .then(() => {
          this.getInQueueDetails(queueId);
          this.$runToast(
            "top-right",
            "default",
            `Queue status has been updated successfully!`
          );
        });
    },
    getQueues() {
      this.$api
        .get(`/store/${this.$route.params.id}/queue`)
        .then((response) => {
          this.queues = response.data.data.list;
          this.queues.forEach((queue) => {
            this.getInQueueDetails(queue.id);
          });
        });
    },
    getInQueueDetails(queueId) {
      this.$api
        .get(`/store/${this.$route.params.id}/queue/${queueId}/in-queue`)
        .then((response) => {
          const data = response.data.data;
          this.inQueues[queueId] = data;
          document
            .querySelectorAll(".queue-online-box")
            .forEach(this.$pointerScroll);
        });
    },
    closeQueueModal() {
      this.modals.queue = false;
      this.model = JSON.parse(JSON.stringify(modelDefault));
      this.getQueues();
    },
    getStore() {
      this.$api
        .get("/store/" + this.$route.params.id)
        .then((response) => {
          this.store = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    this.getStore();
    this.getQueues();
    this.interval = setInterval(() => {
      this.getQueues();
    }, 3000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  components: {
    StatsCard,
    Card,
    QueueModal,
    BaseProgress,
    BaseButton,
    BaseHeader,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    [ElSwitch.name]: ElSwitch,
    [ElTag.name]: ElTag,
    [ElPopconfirm.name]: ElPopconfirm,
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
