<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>

    <!-- Contact card -->
    <div class="container-fluid mt--6">
      <div class="row justify-content-center">
        <div class="col-lg-3 card-wrapper">
          <!-- Grid system -->
          <card class="bg-gradient-danger h-100" v-if="userSubscription">
            <!-- Card body -->
            <div class="row">
              <div class="col">
                <h5
                  class="card-title text-uppercase text-muted mb-0 text-white"
                >
                  Your plan
                </h5>
                <span class="h2 font-weight-bold mb-0 text-white">{{
                  userSubscription.plan.name
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-white text-dark rounded-circle shadow"
                >
                  <i class="fas fa-rocket"></i>
                </div>
              </div>
            </div>
            <div class="mt-3 mb-0 text-white">
              <small>Valid until</small>
              <p class="text-sm" v-if="userSubscription.end_date">
                <span class="text-white mr-2"
                  ><i class="fa fa-calendar"></i>
                  {{ $filters.FormatDate(userSubscription.end_date) }}</span
                >
                <span
                  class="text-nowrap text-light"
                  v-if="userSubscription.subscription_end_in_days"
                  >5 Days left
                  {{ userSubscription.auto_renew ? "(Auto renew)" : "" }}</span
                >
              </p>
            </div>
          </card>
        </div>
        <div class="col-lg-5 card-wrapper">
          <!-- Grid system -->
          <card class="bg-gradient-primary h-100" v-if="userSubscription">
            <!-- Card body -->
            <div class="row">
              <div class="col">
                <h5
                  class="card-title text-uppercase text-muted mb-0 text-white"
                >
                  Stores
                </h5>
                <span class="h2 font-weight-bold mb-0 text-white">{{
                  userSubscription.plan.number_of_stores
                }}</span>
              </div>
              <div class="col">
                <h5
                  class="card-title text-uppercase text-muted mb-0 text-white"
                >
                  Queues
                </h5>
                <span class="h2 font-weight-bold mb-0 text-white">{{
                  userSubscription.plan.number_of_queues_per_store
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-white text-dark rounded-circle shadow"
                >
                  <i class="fas fa-atom"></i>
                </div>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm">
              <span class="text-white mr-2"></span>
            </p>
          </card>
        </div>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="col-lg-8 card-wrapper">
          <!-- Grid system -->
          <card>
            <!-- Card body -->

            <p class="card-text mb-4">
              Start managing your stores by creating a new store and queues
            </p>

            <router-link to="/dashboard/stores">
              <base-button type="primary">Start</base-button>
            </router-link>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {
      stores: [],
    };
  },
  computed: {
    ...mapGetters(["userSubscription"]),
  },
  methods: {},
  async mounted() {},
  components: {
    BaseHeader,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
