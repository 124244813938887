<template>
  <div class="main-content">
    <!-- Header -->
    <div class="header bg-primary pt-5 pb-7">
      <div class="container">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="pr-5">
                <h1 class="display-2 text-white font-weight-bold mb-0">Sraa</h1>
                <h2 class="display-4 text-white font-weight-light">
                  Smartly Waiting System
                </h2>
                <p class="text-white mt-4">
                  Sraa revolutionizes your experience with intelligent queuing!
                  Say goodbye to long lines and hello to efficiency. Simply
                  reserve your spot in-store through Sraa and relax until it's
                  your turn.
                </p>
                <div class="mt-5">
                  <img
                    src="img/landing/apple-badge.svg"
                    alt="sraa"
                    class="img-fluid"
                    width="170"
                  />

                  <img
                    src="img/landing/google-play-badge.png"
                    alt="sraa"
                    class="img-fluid"
                    width="220"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row pt-5">
                <div class="col-md-12">
                  <div class="">
                    <div class="card-body">
                      <img
                        src="img/landing/sraa.svg"
                        alt="sraa"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-info" points="3560 20 1560 20 0 800"></polygon>
        </svg>
      </div>
    </div>
    <section class="py-6 pb-9 bg-info">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-8">
            <h2 class="display-3 text-white">A complete solution</h2>
            <p class="lead text-white">
              Sraa is a complete solution for your business. It provides you
              with a smart queuing system, a dashboard to manage your queues,
              and a mobile app for your customers to reserve their spots.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg pt-lg-0 mt--7">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-4">
                <div class="card card-lift--hover shadow border-0">
                  <div class="card-body py-5">
                    <div
                      class="icon icon-shape bg-gradient-primary text-white rounded-circle mb-4"
                    >
                      <i class="fa fa-mobile"></i>
                    </div>
                    <h4 class="h3 text-primary text-uppercase">
                      Mobiles Applications
                    </h4>
                    <p class="description mt-3">
                      We provide mobile applications for both iOS and Android,
                      for your customers to reserve their spots.
                    </p>
                    <div>
                      <span class="badge badge-pill badge-primary mr-2"
                        >iOS</span
                      >
                      <span class="badge badge-pill badge-primary"
                        >Android</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card card-lift--hover shadow border-0">
                  <div class="card-body py-5">
                    <div
                      class="icon icon-shape bg-gradient-success text-white rounded-circle mb-4"
                    >
                      <i class="fas fa-solar-panel"></i>
                    </div>
                    <h4 class="h3 text-success text-uppercase">
                      Merchant Dashboard
                    </h4>
                    <p class="description mt-3">
                      A full dashboard for the merchant to manage his stores,
                      and queues.
                    </p>
                    <div>
                      <span class="badge badge-pill badge-success">Web</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card card-lift--hover shadow border-0">
                  <div class="card-body py-5">
                    <div
                      class="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4"
                    >
                      <i class="fas fa-question"></i>
                    </div>
                    <h4 class="h3 text-warning text-uppercase">Full support</h4>
                    <p class="description mt-3">
                      We provide full support for our clients, and we are
                      available 24/7.
                    </p>
                    <div>
                      <span class="badge badge-pill badge-warning">24/7</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-6">
      <div class="container mb-5">
        <div class="row justify-content-center text-center">
          <div class="col-md-8">
            <h2 class="display-3 text-primary">Our Plans</h2>
            <p class="lead">
              Choose our Free Plan for essential features, the Growth Plan to
              expand with advanced tools, or the Professional Plan for premium
              features and unparalleled success!
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <Plans></Plans>
      </div>
    </section>
    <section class="py-7 section-nucleo-icons bg-white overflow-hidden">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <h2 class="display-3">Our Clients</h2>
            <p class="lead">
              We're delighted to serve our clients with innovative solutions,
              ensuring their success and satisfaction every step of the way!
            </p>
          </div>
        </div>
        <div class="blur--hover">
          <a href="#">
            <div class="icons-container blur-item mt-5">
              <!-- Center -->
              <img class="icon" src="/img/landing/logo.png" alt="First image" />
              <img src="/img/landing/logo.png" alt="First image" />
              <img class="icon" src="/img/landing/logo.png" alt="First image" />
              <img class="icon" src="/img/landing/logo.png" alt="First image" />
              <img src="/img/landing/logo.png" alt="First image" />
              <img class="icon" src="/img/landing/logo.png" alt="First image" />
              <img class="icon" src="/img/landing/logo.png" alt="First image" />
              <img class="icon" src="/img/landing/logo.png" alt="First image" />
              <img src="/img/landing/logo.png" alt="First image" />
              <img src="/img/landing/logo.png" alt="First image" />
              <img class="icon" src="/img/landing/logo.png" alt="First image" />
              <img class="icon" src="/img/landing/logo.png" alt="First image" />
              <img src="/img/landing/logo.png" alt="First image" />

              <!-- Right 1 -->
              <img src="/img/landing/logo.png" alt="First image" />

              <!-- Right 2 -->
              <img src="/img/landing/logo.png" alt="First image" />
            </div>
            <span class="blur-hidden h5 text-success"
              >Explore all our clients</span
            >
          </a>
        </div>
      </div>
    </section>
    <section class="py-7">
      <div class="container">
        <div class="row row-grid justify-content-center">
          <div class="col-lg-8 text-center">
            <h2 class="display-3">Would you like to talk</h2>
            <div class="btn-wrapper">
              <a
                class="btn btn-neutral mb-3 mb-sm-0"
                target="_blank"
                href="mailto:info@wesla.sa"
              >
                <span class="btn-inner--text">Contact Sraa</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import Plans from "@/views/Subsrcription/component/Plans.vue";

export default {
  name: "Landing",
  data() {
    return {
      stores: [],
    };
  },
  methods: {},
  async mounted() {
    this.getStores();
  },
  components: {
    Plans,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
